import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import ImageRainForest from "../components/img-rainforest"
import theme from "../theme"

const Intro = styled(ContentWrapper)`
  max-width: 590px;

  @media (max-width: ${theme.mediaQuery.small}) {
    text-align: left;
  }

  h1 {
    @media (max-width: ${theme.mediaQuery.small}) {
      text-align: left;
    }
  }
`

const IntroContent = styled(ContentWrapper)`
  max-width: 530px;
  text-align: left;
`

const QuoteWrapper = styled(ContentWrapper)`
  max-width: 800px;

  @media (max-width: ${theme.mediaQuery.small}) {
    text-align: left;
    margin-left: 0;
    padding-left: 5px;
  }  
`

const NarinoContent = styled(ContentWrapper)`
  color: ${theme.color.white}; 
`

const JuanambuContent = styled(ContentWrapper)`
  text-align: left;

  h1 {
    @media (max-width: ${theme.mediaQuery.small}) {
      text-align: left;
    }
  }  
`

const NarinoImg = styled.div`
  margin-bottom: 20px;
`

const Quote = styled.blockquote`
  font-size: ${theme.fontSize.font24};
`

const AboutChiva = () => (
  <Layout>
    
    <SEO 
      title="About us"
      description="Our producers handpick and carefully roast their beans in small batches from their plantations before shipping directly to the UK" />

    <Header />

    <BackgroundWrapper className="backgroundBrownPattern">
      
      <Intro>
        <header>
          <h1>About Chiva</h1>
        </header>
        <p><strong>Chiva coffee was founded by Natalia, a Colombian from Nariño and her husband Graham.</strong></p>
      </Intro>

      <QuoteWrapper>
        <Quote>
          <p>“We were importing coffee from Colombia for our personal consumption long before we set up the company. Every time we visited Colombia we would make it our mission to discover another amazing small coffee producer and bring a batch home.”</p>
        </Quote>
      </QuoteWrapper>

      <IntroContent>
        <p>Our dream was to create a business together doing something we were both passionate about. We want everyone to experience a little bit of Colombia through our coffee and that’s why CH!VA Coffee was born.</p>
        <p>We import from a select group of producers, who each handpick and carefully roast their beans in small batches from their plantations before shipping directly to the UK.</p>
        <p>In addition to striving for coffee drinkers in the UK to experience the best quality beans from Colombia; it is very important for us to collaborate with and reward our Nariño farmers, that’s why we pay a premium to our suppliers to ensure farmers receive a fair price for such an exceptional product.</p>
      </IntroContent>

    </BackgroundWrapper>

    <BackgroundWrapper className="backgroundWhite">
      <JuanambuContent>

        <header>
          <h1>Juanambú foundation</h1>
        </header>
        <p>This non-profit is located in Colombia, in the department of Nariño, where CH!VA coffee comes from. Our region is called Juanambú in honour of the river that crosses the lands.</p>
        <p>Unfortunately 81.66% of the population lives without access to basics elements such as drinking water, food or shelter. Our mission is to help improve the lives of disabled elders who are unable to work and live in abandonment. Our help is focused towards providing their daily meals that supply the basic nutritional needs of the elders that are part of the group.</p>
        <p>The Juanambú foundation is also in charge of screening and selecting coffee farmers who supply our green beans. We seek to purchase from farmers and coffee growing associations with high social impact such as sustainable development and inclusive programs within their communities.</p>
      
      </JuanambuContent>
    </BackgroundWrapper> 

    <BackgroundWrapper className="backgroundBlue">
      <NarinoContent>
        
        <header>
          <h1>Nariño Rainforest</h1>
        </header>

        <NarinoImg>
          <ImageRainForest />
        </NarinoImg>

        <p>Our sustainable coffee is certified by Rainforest Alliance. This certification aims to protect the ecosystems, as well as the people, fauna and flora that are affected by the usage of the soil. Farmers receive an extra fee for maintaining their certification in recognition to their effort.</p>

      </NarinoContent>
    </BackgroundWrapper>

  </Layout>
)

export default AboutChiva
